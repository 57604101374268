import React from 'react'
import Footer from '../Component/Footer'
import Navbar from '../Component/Navnbar'
import TopAboutUS from '../Component/TopAboutUS'

function AboutUS() {
  return (
    <div>
     <Navbar/> 
     <TopAboutUS/>
     <Footer/>
    </div>
  )
}

export default AboutUS
 
import React from 'react'
import Banner from '../Component/Banner'
import BuildMore from '../Component/BuildMore'
import Footer from '../Component/Footer'
import Navbar from '../Component/Navnbar'
import Possibility from '../Component/Possibility'
import Requestnewtemplet from '../Component/Requestnewtemplet'
import SliderTemplet from '../Component/SliderTemplet'
import Traffice from '../Component/Traffice'

function Home() {
  return (
    <div>
        <Navbar/>
        <Banner/>
        <Possibility/>
        <SliderTemplet/>
        <BuildMore/>
        <Traffice/>
        <Requestnewtemplet/>
        <Footer/>
    </div>
  )
}

export default Home
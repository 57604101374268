import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { BsFacebook, BsPhoneVibrateFill } from "react-icons/bs";
import { ImInstagram } from "react-icons/im";
import { AiFillLinkedin, AiOutlineMail } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

function Footer() {
  return (
    <div>
      <footer id="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-3 claasjjsjsimage">
              <Link to="/">
                <img src="/img/logo.png" alt="" class="img-fluid logo-footer" />
              </Link>
              <div class="footer-about">
                <p>
                  {" "}
                  We esteemed ourselves as one of the best in Website Creation,{" "}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="useful-link">
                <h2>Useful Links</h2>
                {/* <img src="./assets/images/about/home_line.png" alt="" class="img-fluid" /> */}
                <div class="use-links">
                  <li>
                    <Link to="/">
                      <FaAngleRight />
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <FaAngleRight />
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/register">
                      <FaAngleRight />
                      Create Account
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaAngleRight /> Contact
                    </Link>
                  </li>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="social-links">
                <h2>Follow Us</h2>
                {/* <img src="./assets/images/about/home_line.png" alt="" /> */}
                <div class="social-icons">
                  <li>
                    <Link to="">
                      <BsFacebook /> Facebook
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <ImInstagram /> Instagram
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <AiFillLinkedin /> Linkedin
                    </Link>
                  </li>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="address">
                <h2>Address</h2>
                {/* <img src="./assets/images/about/home_line.png" alt="" class="img-fluid" /> */}
                <div class="address-links">
                  <li>
                    <MdLocationOn /> Kolathur ramankulam-Malappuram Dt Kerala
                    679338
                  </li>
                  <li>
                    <Link to="">
                      <BsPhoneVibrateFill /> +91 90904500112
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <AiOutlineMail /> mail@1234567.com
                    </Link>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section id="copy-right">
        <div class="copy-right-sec">
          <i class="fa-solid fa-copyright"></i>
          QWIK-TECH @ 2022
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
// import { getRandomString } from '../../../services/utility'
import { useAuth } from "../../Component/CreateUser.js/UserAuth";
import { database, functions } from "../../firebase";
import "./Createuser.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getuserlogin } from "../../Services/Getservices.service";

function Login() {
  const [password, setpassword] = useState("");
  const [email, setemailinput] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { login } = useAuth();

  const [id, setuserid] = useState("");

  function getuserdetails(event) {
    setemailinput(event.target.value);
    const email = event.target.value;
    getuserlogin(email).then((getuser) => {
      
      if (getuser) {
        const activeuser = getuser[0];
        setuserid(activeuser ? activeuser.id : "");
      }
    });
  }



  const handlelogin = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      
        await login(email, password);
        toast.success("Please wait while fetching data");
        localStorage.setItem("userid", id);
          history.push("/auth/checker");
      
    } catch (e) {
      toast.error(e.message);
    }

    setloading(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="creatuserheaser">
        <div className="logosidecreatuser">
          <Link to="/">
            {" "}
            <img src="/img/logo.png" alt="" />
          </Link>
        </div>
      </div>

      <div className="createusercontainer">
        <div className="creatusercontnet">
          <div className="creatusercontene">
            <div className="headersectionlogin">
              <center>
                <h1>Login In</h1>
                <p>
                  Don't have an account{" "}
                  <Link to="/register">
                    <span className="text-info">Register</span>
                  </Link>
                </p>
              </center>
            </div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={getuserdetails} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                />
              </Form.Group>

              <Button
                variant="dark"
                onClick={handlelogin}
                className="w-100"
                type="submit"
              >
                {loading ? <Spinner animation="border" /> : "Login"}
              </Button>
              <Form.Text className="text-muted text-info my-2">
                Forget Password{" "}
                <Link to="/forget_password" className="text-info">
                  Reset Password
                </Link>
              </Form.Text>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

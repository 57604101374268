import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
// import { getRandomString } from '../../../services/utility'
import { useAuth } from "../../Component/CreateUser.js/UserAuth";
import { database, functions } from "../../firebase";
import "./Createuser.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewRegistration() {
  const [message, setmessage] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phone, setphone] = useState(0);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("")
  const history = useHistory();
  const { signup } = useAuth();
  const [loading, setloading] = useState(false);

  const handleregistration = async (e) => {
    e.preventDefault()
    setloading(true);

    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      phone === "" ||
      password === ""
    ) {
      toast.warning('Please fill all input !', {
        position: toast.POSITION.TOP_RIGHT
    });
      return;
    }

    if (password !== confirmpassword){
      toast.warning('Password Mismatch!', {
        position: toast.POSITION.TOP_RIGHT
    });
      return;
    }

    try {
      const cred = await signup(email, password);
      await cred.user
        .sendEmailVerification()
        .then((a) => console.log(a, "res"))
        .catch((e) => console.log(e, "err"));
      const { uid } = cred.user;
      const user = {
        id: uid,
        firstname: firstname,
        lastname: lastname,
        PhoneNumber: phone,
        Email: email,
        permission: "user",
        wallet: 0, 
        isallowed:true,
        TotalAmountBuy: 0,
        TotalRejected:0,
        
        NumberofBuy: 0,

      };

      database
        .collection("users")
        .doc(user.id)
        .set(user)
        .then(() => {
          toast.success('Your account creation was successful !', {
            position: toast.POSITION.TOP_RIGHT
        }); 
        setInterval(() => {
          history.push("/auth/checker");
        }, 2000);
        });
    } catch (error) {
      toast.warning(error.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setloading(false)
  };
  return (
    <div>
      <ToastContainer />
      <div className="creatuserheaser">
        <div className="logosidecreatuser">
        <Link to="/"> <img src="/img/logo.png" alt="" /></Link>
        </div>
      </div>

      <div className="createusercontainer">
        <div className="creatusercontnet">
          <div className="creatusercontene">
            <div className="headersectionlogin">
            <center>
            <h1>Create Account</h1>
              <p>
                Already have an account?{" "}
                <Link to="/login">
                  <span className="text-info">Log In</span>
                </Link>
              </p>
            </center>
            </div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text"  onChange={(e)=>{
                  setfirstname(e.target.value)
                }}  />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" onChange={(e)=>{
                  setlastname(e.target.value)
                }}   />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email"  onChange={(e)=>{
                  setemail(e.target.value)
                }}  />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="tel" onChange={(e)=>{
                  setphone(e.target.value)
                }}  />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password"  onChange={(e)=>{
                  setpassword(e.target.value)
                }}  />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" onChange={(e)=>{
                  setconfirmpassword(e.target.value)
                }}  />
              </Form.Group>

              <Button variant="dark"  onClick={handleregistration}  className="w-100" type="submit">
                {loading ? <Spinner animation="border" /> : "Register"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRegistration;

import { database } from "../firebase";

export const getuser = (id, cb) => {
  console.log(id)
    const ref = database.collection("users");
    let reference = ref;
    if (id) {
      reference = reference.where("id", "==", id);
    }
    reference.onSnapshot((queryproduct) => {
      const items = [];
      queryproduct.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(item);
      });
  
      cb(items);
    });
  }

  export const getuserlogin = (email) => {
    return new Promise((resolve) => {
      const ref = database.collection("users");
      let reference = ref;
      if (email) {
        reference = reference.where("Email", "==", email);
      }
      reference.onSnapshot((queryproduct) => {
        const items = [];
        queryproduct.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          items.push(item);
        });
  
        resolve(items);
      });
    });
  };
import React from "react";
import "./BuildMore.css"

function BuildMore() {
  return (
    <div>
      <div className="buildmorecontainer">
        <div className="buildmorecontner">
          <div className="builditemsd">
            <h1>Create more than a website</h1>
          </div>

          <div className="builditemsd">
            From selling online, starting a blog and organizing events to
            promoting your business and building your community, create a
            website that can grow with you.
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildMore;

import React from "react";
import { useHistory } from "react-router-dom";
import './Possibility.css'

function Possibility() {
    const history =  useHistory()

    const handleTemplet  = ()=>{
 
              history.push("/templete")
    }
  return (
    <div>
      <div className="possinecontner">
        <div className="possibleinfcotner">
            <div className="possibiltuyheader">
            <h1>Boundless Possibility. <br/> One Platform</h1>
            </div>
          <div className="possiblitycontener">
            <div className="possiblityirttem">
                <h1>Creative Creation</h1>
                <p>Create a website with a complete suite of advanced functionalities and bring your vision to life.</p>
            </div>
            <div className="possiblityirttem">
            <h1>Stable Infastructure</h1>
            <p>Get an enterprise-grade foundation, engineered for your limitless scalability and peace of mind.</p>
            </div>
            <div className="possiblityirttem">
            <h1>Active Support</h1>
            <p>Convert and scale seamlessly with built-in marketing and business solutions.</p>
            </div>
          </div>

          <button onClick={handleTemplet}>Get Started</button>
        </div>
      </div>
    </div>
  );
}

export default Possibility;

import React from "react";
import "./AboutUS.css";

function TopAboutUS() {
  return (
    <div>
        {/* style={{ backgroundImage: "url(/img/b2.png)" }} */}
      <div className="bannerheabit">
        <div className="aboutcoeternr"  >
          <div className="bannercontejr">
            <div className="intorabout">
            <h1>We are the Best <br /><span className="text-warning jhsjhsdjdjsjdjdj">in Website Creation</span></h1>
            </div>
            <div className="imageabout">
                <img src="/img/TEAM.png" alt="" />
            </div>
          </div>
        </div>

        <div className="createurpwjconteintr">
          <div className="createouwnpbane">
            <div className="textheaderaboutside">
              Create your own professional web presence—exactly the way you
              want.
            </div>
            <div className="detailsaboutconteinr">
              <p>
                Our powerful technology allows everyone to get online with a
                personalized, professional web presence. Whether it’s your first
                time creating or you’re a long time expert, you’ll find the
                features and solutions you need to build a professional website
                with total creative freedom.
              </p>

              <p>
                Our global user base,  and unparalleled design
                capabilities create a unique ecosystem. Partners, developers,
                web designers, and other online professionals can effectively
                market their apps and services to millions through Wix.
              </p>
            </div>
          </div>
        </div>

        <div className="createaprofession">
            <div className="comteonr">
                <h1>Create Your Professional Website With Us</h1>
                <button>Get Started</button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default TopAboutUS;

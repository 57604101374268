import React, { useState } from 'react'
import "./Contactus.css"
import {FaRegPaperPlane} from "react-icons/fa"
import { ToastContainer,  toast } from 'react-toastify'
import { database } from '../firebase'
import firebase from 'firebase'

function Contactus() {

  const [name, setname]= useState("")
  const [email, setemail]= useState("")
  const [message, setmessage]= useState("")
  const [loading, setloading] =  useState(false)


  const handlesend = async (e) => {
    e.preventDefault()
    setloading(true)
if (name=== "" || email === "" || message === ""){
  toast.error("Please fill all input")
  return
}

    try {
      const uid = database.collection('_').doc().id;
      const data = {
        id: uid,
        name,
        email,
        message,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        isRead:false,
        status:true
      };

      database
        .collection("users")
        .doc(data.id)
        .set(data)
        .then(() => {
          toast.success('Message sent successfully.', {
            position: toast.POSITION.TOP_RIGHT
        }); 
        });
    } catch (error) {
      toast.warning(error.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setloading(false)
  };
  




  return (
    <div>
       <ToastContainer />
         <div className="wrapper">
    <h2>CONTACT US</h2>
    
    <form >
      <div className="form-group">
        <label for="name">Full Name</label>
        <input type="text" name="Name" id="name" onChange={((event)=>{
          setname(event.target.value)
        })} placeholder="First and Last" required minlength="3"/>
      </div>
      
      <div className="form-group">
        <label for="email">Email Address</label>
        <input type="email" name="Email" id="email" onChange={((event)=>{
          setemail(event.target.value)
        })}  placeholder="email@domain.tld" required />
      </div>
      <div className="form-group">
        <label for="message">Message</label>
        <textarea name="Message" id="message" onChange={((event)=>{
          setmessage(event.target.value)
        })}  rows="5" placeholder="Type your message here...." required></textarea>
      </div>
      <div className="form-group">
        <button type="submit" onClick={handlesend}  disabled={loading} className="submit"> <FaRegPaperPlane/>Send</button>
      </div>
    </form>
  </div>
    </div>
  )
}

export default Contactus
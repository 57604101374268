import React from 'react'
import Contactus from '../Component/Contactus'
import Footer from '../Component/Footer'
import Navbar from '../Component/Navnbar'

function Contact() {
  return (
    <div>
        <Navbar/>
    <center><Contactus/></center>
        <Footer/>
    </div>
  )
}

export default Contact
import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from "./Page/Home"
import Login from './Component/CreateUser.js/Login';
import Register from './Component/CreateUser.js/Register';
import ForgetPassword from './Component/CreateUser.js/ForgetPassword';
import Validator from './Component/CreateUser.js/Validator';
import { AuthProvider } from './Component/CreateUser.js/UserAuth';
import AboutUS from './Page/AboutUS';
import Templet from './Component/Templet';
import Contact from './Page/Contact';



function App() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
    
      {loading ? (
        <>
       loading
        </>
      ) : (
        <Router>
     <AuthProvider>
 <Switch>

 <Route path="/" exact component={Home}/>
 
 <Route path="/user/:id" exact component={Home}/>
 <Route path="/login"  component={Login}/>
 <Route path="/register"  component={Register}/>
<Route path="/forget_password"  component={ForgetPassword}/>
<Route path="/auth/checker"  component={Validator}/>
<Route path="/about"  component={AboutUS}/>
<Route path="/templete"  component={Templet}/>
 <Route path="/contact"  component={Contact}/>

  </Switch>
 </AuthProvider>
   </Router>

      )}
    </div>
  );


}

export default App;

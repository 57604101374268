import React, {useEffect, useState} from "react";
import { Link, useHistory} from "react-router-dom";
import "./Navbar.css";
import {MdClose} from 'react-icons/md'
import {BiMenu} from "react-icons/bi"
import { getuser } from "../Services/Getservices.service";
import { Button } from "react-bootstrap";
import { useAuth } from "./CreateUser.js/UserAuth";



function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [user, setuser] =  useState([])
  const [istrue , setistrue] = useState(false)
  const history = useHistory()
  

  useEffect(() => {
    const user = localStorage.getItem("userid")
    if(user){
          getuser(user, (collections) => {
            console.log(collections)
            const user =  collections[0]
           setuser(user)
           setistrue(true)
        });
    }
  
  }, [])



  const handlelogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userid");
    setistrue(false)
    history.push("/");
  };

  return (
    <>
      <nav className="navbar1">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src="/img/logo.png" alt="" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <MdClose/> : <BiMenu/>}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className="nav-links"
              onClick={closeMobileMenu}
              
            >
              Home
            </Link>
          </li>
          
          <li className="nav-item">
            <Link
              to="/templete"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Template 
            </Link>
          </li>
         
          {/* <li className="nav-item">
            <Link to="/academy" className="nav-links" onClick={closeMobileMenu}>
              Academy 
            </Link>
          </li> */}
            <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact 
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
       
          <li className={istrue === true ? "nav-item" : "d-none"} >
          <span>Welcome {user.firstname} |  <Button onClick={handlelogout} variant="danger">Logout</Button></span>
          </li>

          <li className={istrue === true ? "d-none" : "nav-item"}>
          <Link to="/login" className="nav-links" onClick={closeMobileMenu}>
              Login
            </Link>

            | 

            <Link to="/register" className="nav-links" onClick={closeMobileMenu}>
              Register
            </Link>
          </li>
       
        
        </ul>
      </nav>
    </>
  );
}

export default Navbar;

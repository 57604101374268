import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'

const FirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCAkvpOSv1wvlwRHLHifz0Kh8gorn-GfKI",
  authDomain: "qwiktech-app.firebaseapp.com",
  projectId: "qwiktech-app",
  storageBucket: "qwiktech-app.appspot.com",
  messagingSenderId: "359230077493",
  appId: "1:359230077493:web:e20726bf4acd48f07f4f7c"
});

export const auth = FirebaseApp.auth()
export const database = FirebaseApp.firestore();
const storage = firebase.storage()
// const functions = firebase.functions()

export  {
  storage, FirebaseApp as default
}

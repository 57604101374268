import React from "react";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Slider.css"

function SliderTemplet() {
  const history =  useHistory()

  const handlecontactus = ()=>{
    history.push("/contact")
  }
  return (
    <div>
      <div className="bannercontainer">
        <div className="bannercontnenr">
          <div className="topsectionbanner">
            <h1>Website templates that set you up for business</h1>
            <p>
              Get a headstart on your journey with 1500+ free,{" "}
              <span className="text-warning">
                customizable website templates
              </span>
              , strategically researched and tailored for every industry — or
              start from a blank canvas on our website builder.
            </p>
            
          </div>
          <div className="bannndersodf">
            <div className="mainbanners">
              <Carousel>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a1.jpeg"
                    alt="img"
                    height="650"
                  />
                    
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a2.jpeg"
                    alt="img"
                    height="650"
                  />
                   
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a.jpeg"
                    alt="img"
                    height="650"
                  />
                    
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t5.jpeg"
                    alt="img"
                    height="650"
                  />
                   
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t4.jpeg"
                    alt="img"
                    height="650"
                  />
                   
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t3.jpeg"
                    alt="img"
                    height="650"
                  />
                 
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t2.jpeg"
                    alt="img"
                    height="650"
                  />
                 
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t1.jpeg"
                    alt="img"
                    height="650"
                  />
                   
                </Carousel.Item>


              </Carousel>

              
            </div>

            <div className="mainbannersmobile">
              <Carousel>
                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a1.jpeg"
                    alt="img"
                    height="450"
                  />
             
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a2.jpeg"
                    alt="img"
                    height="450"
                  />
                  
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/a.jpeg"
                    alt="img"
                    height="450"
                  />
                   
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t5.jpeg"
                    alt="img"
                    height="450"
                  />
                  
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t4.jpeg"
                    alt="img"
                    height="450"
                  />
                    
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t3.jpeg"
                    alt="img"
                    height="450"
                  />
                    
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t2.jpeg"
                    alt="img"
                    height="450"
                  />
                 
                </Carousel.Item>

                <Carousel.Item interval={1000}>
                  <img
                    className="d-block w-100"
                    src="/img/t1.jpeg"
                    alt="img"
                    height="450"
                  />
                  
                </Carousel.Item>


              </Carousel>
            </div>
            <button onClick={handlecontactus}>make an enquiry</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderTemplet;

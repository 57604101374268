import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
// import { getRandomString } from '../../../services/utility'
import { useAuth } from "../../Component/CreateUser.js/UserAuth";
import { database, functions } from "../../firebase";
import "./Createuser.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgetPassword() {
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { resetPassword } = useAuth();

  async function handlereset(e) {
    e.preventDefault();
    setloading(true);
    try {
      await resetPassword(email);
      toast.success("Check your inbox for further instructions");
      setInterval(() => {
        history.push("/login");
      }, 3000);
    } catch (e) {
      toast.error(e.message);
    }
    setloading(false);
  }


  return (
    <div>
      <ToastContainer />
      <div className="creatuserheaser">
        <div className="logosidecreatuser">
        <Link to="/"> <img src="/img/logo.png" alt="" /></Link>
        </div>
      </div>

      <div className="createusercontainer">
        <div className="creatusercontnet">
          <div className="creatusercontene">
            <div className="headersectionlogin">
            <center>
            <h1>Reset Password</h1>
            </center>
            </div>
            <Form>


              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email"  onChange={(e)=>{
                  setemail(e.target.value)
                }}  />
              </Form.Group>

              <Button variant="dark"  onClick={handlereset}  className="w-100" type="submit">
                {loading ? <Spinner animation="border" /> : "Reset Password"}
              </Button>
              <Form.Text className="text-muted text-info my-2">
                I can remember my password <Link to="/login" className="text-info">Login</Link>
        </Form.Text>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;

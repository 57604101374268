import React from "react";
import "./Trafric.css"

function Traffice() {
  return (
    <div>
      <div className="traficconaoner">
        <div className="trafficecontenr">
          <div className="traddicmessage">
            <div className="headermesssgae">
              <h1>Impression that Drive traffic</h1>
            </div>
            <div className="messgagsbidy">
              <p>Promote your business and reach the right audience:</p>
            </div>
            <div className="messslistir">
              - Complete SEO solution <br />
              - AI-driven FB & IG Ads <br />
              - Email campaigns <br />
              - Social posts and more <br />
            </div>

            <button>Get Started</button>
          </div>
          <div className="trificgridad">
          <img src="/img/t6.jpeg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Traffice;

import React, {useEffect, useState}from "react";
import { useAuth } from "../CreateUser.js/UserAuth";
import { database } from "../../firebase";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import {Link} from 'react-router-dom'
import "./Createuser.css"

// import Spiner from "../Spiner";

function WelcomePage() {
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [message, setmessage] = useState('')
  const {uid, emailVerified } = useAuth().currentUser
  
  const history = useHistory();

  
 

  const getUser = async () => {
      
    try {
      const documentSnapshot = await database
        .collection("users")
        .doc(uid)
        .get();
     const userData = documentSnapshot.data()  

    // checking user permission 


     if (emailVerified && userData.permission === 'user'){
      
      history.push(`/`)
      
      return; 
    }
  
  
      setUser(userData);
    } catch(e) {
        setmessage(e.message);
    }  
  };
  useEffect(() => {
    getUser();
  }, []);
 
  const resetLink = async (e) =>{
    firebase.auth().currentUser.reload() // reloads user fields, like emailVerified:
  if (!firebase.auth().currentUser.emailVerified) {
      //resend verification email
      await currentUser.sendEmailVerification()
    .then((a) => e.message)
    .catch((e) => e.message);
    setmessage('Activation Link sent');
  } 
  }


  return (!user ? <div>
    <>Loading</>
  </div> : 
    <div>
      <div className="welcome1">
        <div className="welcomeman">
            <center><div className="indv">
            <Link to="/"> <img src="/img/logo.png" alt="" /></Link>
            </div></center>
         
          <div className="welcomegrand">
          Welcome <strong></strong> {currentUser.email}
            <div className="welcomeparent">
              <div className="welcomechil">Your Account need To be Verified; <br/>
              Please click on the link sent to your Email to verify your account with smile</div> <br/>
              <p>If you didnt perform this operation please Contact us </p> <span><Link to='/contact'>Support</Link></span> <br/>
              <Link to = '/services'>
                Check Services
              </Link>
            </div>
          </div>

          <div className="restbuton">
            <button onClick={resetLink}> Send verification Link</button> <br/>
            {message}
          </div>

        </div>
      </div>
   
    </div>
  );
}

export default WelcomePage;

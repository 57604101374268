import React from 'react'
import "./Banner.css"

function Banner() {
  return (
    <div>
        <div className="bannerconatiner">
            <div className="bannerimage">
                <div className="bannerconeje">
                    <h2>Order a website template <span className='text-warning'>for your business</span> </h2>
                    <button>Get Started...</button>
                </div>
                <div className="bannerimagessiosd">
                    <img src="/img/b.png" alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Banner
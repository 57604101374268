import React from "react";
import { Button, Card, Col, Container, Row, ThemeProvider} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navnbar";
import "./Templet.css"

function Templet() {
  const history =  useHistory()

  const handlecontact = ()=>{
    history.push("/contact")
  }
  return (
    <div>
      <Navbar />
  

      <div className="cardocntaine">
        <div className="templetaatheaderall">
            <p>Please select your preferred template</p>
        </div>
        <div className="cardconrent">
            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/t1.jpeg" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>
            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/t5.jpeg" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>

            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/t4.jpeg" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>
            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/t3.jpeg" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>
            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/t2.jpeg" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>
            <div className="cardwrapper">
            <Card>
          <Card.Img variant="top" src="/img/a1.jpeg" className="imghsa" />
          <Card.Body>
            <Card.Title>Business Bortfolio Template</Card.Title>
            <Button className="w-100" onClick={handlecontact} variant="warning">
              Order Now
            </Button>
          </Card.Body>
        </Card>
            </div>
         
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Templet;

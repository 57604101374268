import React from 'react'
import { Button } from 'react-bootstrap'
import "./Request.css"

function Requestnewtemplet() {
  return (
    <div>
        <div className="newproductwrapper">
            <div className="newtallitems">
                <div className="firstsidecontainers">
                <h1>How to Request for your Template</h1>
                <p>Follow these 6 simple steps to create a website today</p>
                </div>
                <div className="othersidecontainer">
                    <ul>
                        <li><strong>Sign up for a free website builder.</strong> Choose what kind of website you want to create.</li>
                        <li><strong>Customize a template or get a website made for you..</strong>Choose your starting point.</li>
                        <li><strong>Drag and drop 1000s of design features.</strong> Add text, galleries, videos, vector art and more.</li>
                        <li><strong>Get ready for business.</strong>Add an online store, booking system, members area and blog.</li>
                        <li><strong>Publish your website and go live. </strong>Start building your professional online presence.</li>
                        <li><strong>Drive traffic to your site. </strong> Use advanced SEO tools and integrated marketing solutions.</li>
                    </ul>

                    <Button variant="dark">Get Started</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Requestnewtemplet